import type { ISbStoryData } from 'storyblok-js-client'
import type { BreadcrumbsLD } from './useBreadcrumbs'
import type { EventLd } from '@/utils/structured-data-adapters'
import { organizationData } from '@/utils/static-structured-data'

type MetaAttributes = { hid?: string; name: string; content: string }
type MetaScript = {
  src?: string
  type: string
  async: boolean
  defer: boolean
  innerHTML?: string
}
type MetaOptions = {
  metaArgs?: MetaAttributes[]
  linkArgs?: MetaAttributes[]
  additionalScripts?: string[] // TODO: add full Script Object type to pass as an argument
  jsonLd?:
    | BreadcrumbsLD[]
    | EventLd[]
    | BreadcrumbsLD
    | EventLd
    | typeof organizationData
  title?: string
}

export const useCustomHead = (
  story: MaybeRef<ISbStoryData<any>>,
  options?: MetaOptions,
) => {
  const metaData = unref(story)?.content.meta

  const i18nHead = useLocaleHead({
    addSeoAttributes: true,
  })

  const noIndex = computed(() => {
    return unref(story)?.content?.noindex
      ? [
          {
            name: 'robots',
            content: 'noindex',
          },
        ]
      : []
  })
  let script: MetaScript[] = []

  if (options?.additionalScripts && options?.additionalScripts.length > 0) {
    script = options?.additionalScripts?.map((src) => ({
      src,
      type: 'text/javascript',
      async: true,
      defer: true,
    }))
  }

  if (options?.jsonLd) {
    if (Array.isArray(options.jsonLd)) {
      script = [
        ...script,
        ...options.jsonLd.map((jsonLd) => ({
          innerHTML: JSON.stringify(jsonLd),
          type: 'application/ld+json',
          async: true,
          defer: true,
        })),
      ]
    } else {
      script = [
        ...script,
        {
          innerHTML: JSON.stringify(options.jsonLd),
          type: 'application/ld+json',
          async: true,
          defer: true,
        },
      ]
    }
  }

  const route = useRoute()

  const canonicalLink = reactive({
    rel: 'canonical',
    href: `https://infermedica.com${route.path}`,
  })

  useHead({
    htmlAttrs: {
      lang: i18nHead.value.htmlAttrs?.lang,
    },
    script,
    title: options?.title || metaData?.title,
    link: [canonicalLink, ...(options?.linkArgs || [])],
    meta: [
      ...(i18nHead.value.meta || []),
      ...(options?.metaArgs || []),
      ...noIndex.value,
      {
        hid: 'title',
        name: 'title',
        content: metaData?.title,
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: metaData?.og_title || metaData?.title,
      },
      {
        hid: 'twitter:title',
        name: 'twitter:title',
        content: metaData?.twitter_title || metaData?.title,
      },
      {
        hid: 'description',
        name: 'description',
        content: metaData?.description,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: metaData?.og_description || metaData?.description,
      },
      {
        hid: 'twitter:description',
        name: 'twitter:description',
        content: metaData?.twitter_description || metaData?.description,
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: metaData?.og_image ?? metaData?.twitter_image ?? '',
      },
      {
        hid: 'twitter:image',
        name: 'twitter:image',
        content: metaData?.twitter_image ?? metaData?.og_image ?? '',
      },
      {
        hid: 'og:image:secure_url',
        property: 'og:image:secure_url',
        content: metaData?.og_image ?? metaData?.twitter_image ?? '',
      },
      {
        hid: 'og:image:alt',
        property: 'og:image:alt',
        content: metaData?.og_title || metaData?.title,
      },
    ],
  })
}
